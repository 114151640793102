import React, { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Redirect, Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';

import PrivateRoute from '../components/CustomRoute/PrivateRoute';
import { useGetStandortPath } from '../components/VHost/StandortRoute';
import { useSelector } from 'react-redux';
import { IS_DEV } from '../../config';
import { currentHostIsShopSystemSelector } from '../../modules/selectors/hosts';
import { useStandortHistory } from '../components/VHost/StandortHistory';
import { enableCompleteWheelsSelector, enablePromotionLandingpagesSelector } from '../../modules/selectors/standort/theme';
import { markenStandortSelector } from '../../modules/selectors/standort/markenStandort';
import { apiClient } from '../../helpers/apiHelperV2';
import useSWR from 'swr';
import InlineSpinner from '../components/Spinner/InlineSpinner';
import ServiceCategoryList from './Category/ServiceCategoryList';
import _ from 'lodash';

const VHostTest = IS_DEV && loadable(() => import('../components/VHost/VHostTest'));
const Styleguide = IS_DEV && loadable(() => import('./Styleguide/Styleguide'));
const Landingpages = loadable(() => import('./Landingpages/Landingpages'));
const LandingPageContent = loadable(() => import('./ServicesLandingPages/LandingpageContent'));
const LegacyShopWrapper = loadable(() => import('../LegacyShopWrapper'));
const ProductDetail = loadable(() => import('./ProductDetail/ProductDetail'));
const TireDetail = loadable(() => import('./TireDetail/TireDetail'));
const HomeSelector = loadable(() => import('./Home/HomeSelector'));
const ServiceRequestPage = loadable(() => import('./ServiceRequest/ServiceRequestPage'));
const ServiceNavigator = loadable(() => import('./Servicenavigator/ServiceNavigator'));
const CategorySwitch = loadable(() => import('./Category/CategorySwitch'));
const AuthenticationSelector = loadable(() => import('./Authentication/AuthenticationSelector'));
const ServiceDetail = loadable(() => import('./ServiceDetail/ServiceDetail'));
const Services = loadable(() => import('./Services/Services'));
const TireConfiguratorPage = loadable(() => import('./Tires/TireConfiguratorPage'));
const Footerpages = loadable(() => import('./Footerpages/Footerpages'));
const AccountOverview = loadable(() => import('./Account/AccountOverview'));
const OpenOrders = loadable(() => import('./Account/OpenOrders'));
const OrderHistory = loadable(() => import('./Account/OrderHistory'));
const Account = loadable(() => import('./Account/AccountForm'));
const UeberUnsSwitch = loadable(() => import('./Ueberuns/SwitchUeberUns'));
const Tires = loadable(() => import('./Tires/Tires'));
const RedirectTires = loadable(() => import('./Tires/RedirectTires'));
const Register = loadable(() => import('./Authentication/RegisterForm'));
const Cart = loadable(() => import('./Cart/Cart'));
const Glossar = loadable(() => import('./Glossar/Glossar'));
const FAQ = loadable(() => import('./FAQ/Faq'));
const Trends = loadable(() => import('./TestUndTrends/Trends'));
const Tipps = loadable(() => import('./TestUndTrends/Tipps'));
const SearchResult = loadable(() => import('./SearchResults/SearchResults'));
const InititateCheckout = loadable(() => import('./Checkout/InitiateCheckout'));
const AdresseCheckout = loadable(() => import('./Checkout/Adresse'));
const ZahlungCheckout = loadable(() => import('./Checkout/Zahlung'));
const ZahlungPaypalCheckout = loadable(() => import('./Checkout/ZahlungPaypal'));
const BestaetigungKaufCheckout = loadable(() => import('./Checkout/BestaetigungKauf'));
const BestaetigungKaufPaypalCheckout = loadable(() => import('./Checkout/BestaetigungKaufPaypal'));
const BestaetigungReservierungCheckout = loadable(() => import('./Checkout/BestaetigungReservierung'));
const ReservationDetailsCheckout = loadable(() => import('./Checkout/ReservationDetails'));
const DankeCheckout = loadable(() => import('./Checkout/Danke'));
const DankeFuerReservierungCheckout = loadable(() => import('./Checkout/DankeFuerReservierung'));
const KontaktCheckout = loadable(() => import('./Checkout/Kontakt'));
const Rims = loadable(() => import('./Rims/Rims'));
const RimConfigurator = loadable(() => import('./RimConfigurator/RimConfigurator'));
const Tecar = loadable(() => import('./Tires/TecarFinder'));
const TecarGarantie = loadable(() => import('./Landingpages/TecarGarantie'));
const PasswordReset = loadable(() => import('./PasswordReset/PasswordReset'));
const PasswordResetInitialization = loadable(() => import('./Authentication/PasswordResetInitialization'));
const NotFound = loadable(() => import('./ErrorPages/NotFound/NotFound'));
const BreadCrumbSelector = loadable(() => import('../components/BreadCrumb/BreadCrumbSelector'));
const CompleteWheelConfigurator = loadable(() => import('./CompleteWheels/CompleteWheelConfigurator'));
const CompleteWheels = loadable(() => import('./CompleteWheels/CompleteWheels'));
const CompleteWheel = loadable(() => import('./CompleteWheels/CompleteWheel'));
const Scheibenreparatur = loadable(() => import('./Landingpages/Scheibenreparatur'));
const ServicenaigatorSteps = loadable(() => import('./Servicenavigator/ServicenaigatorSteps'))
const Merkliste = loadable(() => import('./Merkliste/Merkliste'))


export default function Routes() {
    const currentHostIsShopSystem = useSelector(currentHostIsShopSystemSelector);

    const getStandortPath = useGetStandortPath({ currentHostIsShopSystem });
    const markenStandort = useSelector(markenStandortSelector);

    const { data: serviceNavigatorstatus } = useSWR(`/api/v1/getServicenavigatorStatus?standortId=${markenStandort._id}`, async url => {
        const { data } = await apiClient.get(url);
        return data;
    });

    const serviceNavigatorStatus = useMemo(() => serviceNavigatorstatus?.status, [serviceNavigatorstatus]);

    // TODO: Remove route props for all refactored routes
    //  function components should simply use the appropriate router hooks.
    const history = useStandortHistory();

    const enablePromotionLandingpages = useSelector(enablePromotionLandingpagesSelector);
    const hasCompleteWheels = useSelector(enableCompleteWheelsSelector);

    return (
        <Switch>
            {IS_DEV && (
                <Route exact path={getStandortPath('/vhost')}>
                    {props => <VHostTest {...props} history={history} />}
                </Route>
            )}
            {IS_DEV && (
                <Route exact path={getStandortPath('/styleguide')}>
                    <Styleguide />
                </Route>
            )}
            <Route exact path={getStandortPath('/initiateCheckout')}>
                {props => (
                    <LegacyShopWrapper>
                        <InititateCheckout {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>
            <Route exact path={getStandortPath('/checkout/adresse')}>
                {props => (
                    <LegacyShopWrapper>
                        <AdresseCheckout {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>
            <Route exact path={getStandortPath('/checkout/zahlung')}>
                {props => (
                    <LegacyShopWrapper>
                        <ZahlungCheckout {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>
            <Route exact path={getStandortPath('/checkout/zahlungPaypal')}>
                {props => (
                    <LegacyShopWrapper>
                        <ZahlungPaypalCheckout {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>
            <Route exact path={getStandortPath('/checkout/kontakt')}>
                {props => (
                    <LegacyShopWrapper>
                        <KontaktCheckout {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>
            <Route exact path={getStandortPath('/checkout/bestaetigungKauf')}>
                {props => (
                    <LegacyShopWrapper>
                        <BestaetigungKaufCheckout {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>
            <Route exact path={getStandortPath('/checkout/bestaetigungKaufPaypal')}>
                {props => (
                    <LegacyShopWrapper>
                        <BestaetigungKaufPaypalCheckout {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>
            <Route exact path={getStandortPath('/checkout/bestaetigungReservierung')}>
                {props => (
                    <LegacyShopWrapper>
                        <BestaetigungReservierungCheckout {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>
            <Route exact path={getStandortPath('/checkout/standortAuswahl')}>
                {props => (
                    <LegacyShopWrapper>
                        <ReservationDetailsCheckout {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>
            <Route exact path={getStandortPath('/checkout/danke/:orderId?')}>
                {props => (
                    <LegacyShopWrapper>
                        <DankeCheckout {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>
            <Route exact path={getStandortPath('/checkout/dankeFuerReservierung/:orderId?')}>
                {props => (
                    <LegacyShopWrapper>
                        <DankeFuerReservierungCheckout {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>

            <Route exact path={getStandortPath('/serviceAnfrage/:serviceUrl?')}>
                <ServiceRequestPage />
            </Route>

            <Route exact path={getStandortPath('/home/tecarLandingpage/:showConfigurator(reifen-finden)?')}>
                <Tecar />
            </Route>
            <Route exact path={getStandortPath('/warenkorb')}>
                {props => (
                    <LegacyShopWrapper>
                        <Cart {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>

            <Route exact path={getStandortPath('/fachbegriffe')}>
                <Glossar />
            </Route>
            <Route exact path={getStandortPath('/faq')}>
                <FAQ />
            </Route>
            <Route exact path={getStandortPath('/TestErgebnisse')}>
                <Trends />
            </Route>
            <Route exact path={getStandortPath('/TippsTrends')}>
                <Tipps />
            </Route>

            {/* SEARCH */}
            <Route exact path={getStandortPath('/search')}>
                <SearchResult />
            </Route>

            {/* Registrieren */}
            <Route exact path={getStandortPath('/registrieren')}>
                {props => (
                    <LegacyShopWrapper>
                        <Register {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>

            {/* Detail pages */}
            <Route exact path={getStandortPath('/reifen/tecar/:zip/:carMake')}>
                <Tecar />
            </Route>
            <Route exact path={getStandortPath('/reifen/:id')}>
                <TireDetail />
            </Route>

            <Route exact path={getStandortPath('/home/aktionen/tecar-reifen')}>
                {props => (
                    <LegacyShopWrapper>
                        <TecarGarantie {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>

            {/* Landingpages */}
            {enablePromotionLandingpages && (
                <Route path={getStandortPath('/home/aktionen')}>
                    {props => (
                        <LegacyShopWrapper>
                            <Landingpages {...props} history={history} />
                        </LegacyShopWrapper>
                    )}
                </Route>
            )}

            <Route exact path={getStandortPath('/scheibenreparatur')}>
                <Scheibenreparatur />
            </Route>
            {/* Reifen */}
            <Route exact path={getStandortPath('/reifen')}>
                <Tires />
            </Route>
            <Route exact path={getStandortPath('/reifen/:autoTyp/:tireSeason/:width/:height?/:zoll?/:hersteller?')}>
                {props => (
                    <LegacyShopWrapper>
                        <RedirectTires {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>
            <Route exact path={getStandortPath('/:seasonUrl(sommerreifen|winterreifen|ganzjahresreifen)')}>
                <TireConfiguratorPage />
            </Route>

            {/* Footerlinks */}
            <Route exact path={getStandortPath('/agb')}>
                {props => (
                    <LegacyShopWrapper>
                        <Footerpages {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>
            <Route exact path={getStandortPath('/impressum')}>
                {props => (
                    <LegacyShopWrapper>
                        <Footerpages {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>
            <Route exact path={getStandortPath('/copyright')}>
                {props => (
                    <LegacyShopWrapper>
                        <Footerpages {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>
            <Route exact path={getStandortPath('/kontakt')}>
                {props => (
                    <LegacyShopWrapper>
                        <Footerpages {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>
            <Route exact path={getStandortPath('/gutscheine')}>
                {props => (
                    <LegacyShopWrapper>
                        <Footerpages {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>
            <Route exact path={getStandortPath('/widerrufsrecht')}>
                {props => (
                    <LegacyShopWrapper>
                        <Footerpages {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>
            <Route exact path={getStandortPath('/datenschutz')}>
                {props => (
                    <LegacyShopWrapper>
                        <Footerpages {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>
            <Route exact path={getStandortPath('/lieferUndVersand')}>
                {props => (
                    <LegacyShopWrapper>
                        <Footerpages {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>
            <Route exact path={getStandortPath('/presse')}>
                {props => (
                    <LegacyShopWrapper>
                        <Footerpages {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>
            <Route exact path={getStandortPath('/versandkosten')}>
                {props => (
                    <LegacyShopWrapper>
                        <Footerpages {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>
            <Route exact path={getStandortPath('/bezahlung')}>
                {props => (
                    <LegacyShopWrapper>
                        <Footerpages {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>
            <Route exact path={getStandortPath('/entsorgungUndUmwelt')}>
                {props => (
                    <LegacyShopWrapper>
                        <Footerpages {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>
            <Route exact path={getStandortPath('/ueberuns')}>
                <UeberUnsSwitch />
            </Route>

            {/* Mein Konto */}
            <PrivateRoute exact path={getStandortPath('/account')}>
                <AccountOverview />
            </PrivateRoute>
            <PrivateRoute exact path={getStandortPath('/account/orders')}>
                <OpenOrders />
            </PrivateRoute>
            <PrivateRoute exact path={getStandortPath('/account/history')}>
                <OrderHistory />
            </PrivateRoute>
            <PrivateRoute exact path={getStandortPath('/account/settings')}>
                <Account />
            </PrivateRoute>

            {/* Home */}
            <Route exact path={getStandortPath(['/home', '/'])}>
                <HomeSelector />
            </Route>
            <Route exact path={getStandortPath('/login')}>
                <AuthenticationSelector />
            </Route>
            <Route exact path={getStandortPath('/passwort-vergessen/:hash')}>
                {props => (
                    <LegacyShopWrapper>
                        <PasswordReset {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>
            <Route exact path={getStandortPath('/passwort-vergessen')}>
                {props => (
                    <LegacyShopWrapper>
                        <PasswordResetInitialization {...props} history={history} />
                    </LegacyShopWrapper>
                )}
            </Route>

            <Route exact path={getStandortPath('/essen/:landingPageShop')}>
                <LandingPageContent />
            </Route>
            <Route exact path={getStandortPath('/duesseldorf/:landingPageShop')}>
                <LandingPageContent />
            </Route>

            <Route exact path={getStandortPath('/servicenavigator')}>
                {_.isNil(serviceNavigatorStatus)  && <InlineSpinner />}
                {serviceNavigatorStatus === true && <ServiceNavigator withBreadcrumbs />}   
                {serviceNavigatorStatus === false && 
                <>
                    <BreadCrumbSelector additionalCrumbs={[{ label: '404', url: `/notFound` }]} />
                    <NotFound />
                </>
                }
            </Route>
            {/* <Route exact path={getStandortPath('/servicenavigatorExtern')}>
                <ServiceNavigator/>
            </Route> */}

            <Route exact path={getStandortPath('/servicenavigator/:service')}>
                {_.isNil(serviceNavigatorStatus)  && <InlineSpinner />}
                {serviceNavigatorStatus === true && <ServicenaigatorSteps withBreadcrumbs />} 
                {serviceNavigatorStatus === false && 
                <>
                    <BreadCrumbSelector additionalCrumbs={[{ label: '404', url: `/notFound` }]} />
                    <NotFound />
                </>
                }      
            </Route>

            {/* ServiceDetail */}
            <Route exact path={getStandortPath('/reparatur-wartung/:serviceUrl/:serviceId')}>
                {_.isNil(serviceNavigatorStatus)  && <InlineSpinner />}
                {serviceNavigatorStatus === false && <ServiceDetail />}
                {serviceNavigatorStatus === true && <Redirect to="/servicenavigator"/>}
            </Route>
           
            <Route exact path={getStandortPath('/reparatur-wartung/:serviceUrl')}>
                {_.isNil(serviceNavigatorStatus)  && <InlineSpinner />}
                {serviceNavigatorStatus === false && <Services />}
                {serviceNavigatorStatus === true && <Redirect to="/servicenavigator"/>}
            </Route>
            <Route exact path={getStandortPath('/reparatur-wartung')}>
                {_.isNil(serviceNavigatorStatus)  && <InlineSpinner />}
                {serviceNavigatorStatus === false && 
                <>
                    <BreadCrumbSelector />
                    <ServiceCategoryList />
                </>
                }
                {serviceNavigatorStatus === true && <Redirect to="/servicenavigator"/>}
            </Route>
            <Route exact path={getStandortPath('/merkliste')}>
                <Merkliste />
            </Route>
    
        
        



            {/*Felgen*/}
            <Route exact path={getStandortPath('/:categoryUrl(alufelgen|stahlfelgen)')}>
                <Rims />
            </Route>
            <Route exact path={getStandortPath('/felgenkonfigurator/:carId')}>
                <RimConfigurator />
            </Route>
            {hasCompleteWheels && (
                <Route exact path={getStandortPath('/komplettrad')}>
                    <CompleteWheelConfigurator />
                </Route>
            )}
            {hasCompleteWheels && (
                <Route exact path={getStandortPath('/komplettraeder')}>
                    <CompleteWheels />
                </Route>
            )}
            {hasCompleteWheels && (
                <Route exact path={getStandortPath('/komplettraeder/:id')}>
                    <CompleteWheel />
                </Route>
            )}
            {/* Muss die letzte Route vor dem Errorhandling sein, da sie sonst alle Routen überschreibt */}
            <Route exact path={getStandortPath('/:categoryUrl/Artikel/:productUrl/:exakt(exakt)?')}>
                {props => <ProductDetail {...props} history={history} />}
            </Route>
            <Route exact path={getStandortPath('/:categoryUrl/:productUrl/:exakt(exakt)?')}>
                {props => <ProductDetail {...props} history={history} />}
            </Route>
            <Route exact path={getStandortPath('/:categoryUrl')}>
                <CategorySwitch />
            </Route>
            <Route exact path={getStandortPath('/:categoryUrl/filters/:filters?')}>
                <CategorySwitch />
            </Route>
            <Route>
                <BreadCrumbSelector additionalCrumbs={[{ label: '404', url: `/notFound` }]} />
                <NotFound />
            </Route>
        </Switch>
    );
}
